<template>
  <div>
    <div
      class="event-detail"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content">
        <div class="event-detail__title" v-html="EventDetail.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          {
            name: EventDetail.name,
          },
        ]"
      />
      <div class="content">
         <div class="event-detail__about-title mt-6">Предлагаем Вам посмотреть наиболее полезную часть или прослушать
            своего любимого эксперта из вебинара года: «ГАЛАКТИКА» - ОБЪЕДИНЯЯ
            ПОКОЛЕНИЯ».</div>
        <div class="event-detail__about-description">
          Из видео Вы узнаете:
          <br /><br />
          <ul style="color: #656969 !important">
            <li>Липидную теорию атеросклероза;</li>
            <li>Данные по безопасности применения статинов в 2023 году;</li>
            <li>Обновленные рекомендации: "нарушения липидного обмена";</li>
            <li>Ряд любопытных случаев о тактике применения статинов;</li>
          </ul>
          и многое другое...
        </div>
        <!-- <picture>
          <source
            :srcset="require('@/assets/img/galaxy/banner_mob.jpg')"
            media="(max-width: 767px)"
          />
          <source
            :srcset="require('@/assets/img/galaxy/banner_tab.jpg')"
            media="(max-width: 1220px)"
          />
          <source
            :srcset="require('@/assets/img/galaxy/banner.jpg')"
            media="(min-width: 1221px)"
          />
          <img
            class="event-detail__banner mt-8"
            :srcset="require('@/assets/img/galaxy/banner.jpg')"
          />
        </picture> -->
        <!-- <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                {{ $m(EventDetail.date).format("DD MMMM") }},
                {{
                  $m
                    .utc(EventDetail.date)
                    .utcOffset(+3)
                    .format("HH:mm")
                }}
                (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Место</div>
              <div class="event-detail__date-item-value">
                {{ EventDetail.place }}
              </div>
            </div>
            <div class="event-detail__date-item">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                Очно + онлайн трансляция
              </div>
            </div>
          </div>
          <div
            class="event-detail__add-calendar button button_empty-pink"
            @click="getCalendar(EventDetail.id)"
          >
            Добавить в календарь
          </div>
        </div> -->
        <!-- <div class="event-detail__timer mb-0">
          <div class="event-detail__timer-title mb-sm-0">
            {{
              !Object.keys(timerResult).length
                ? "Мероприятие началось"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Мероприятие начнется через:"
            }}
          </div>
          <div class="event-detail__timer-date mt-sm-4" v-if="timerResult.months">
            {{ $m(EventDetail.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetail.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="
              (Object.keys(IframeAccess).length || EventDetail.webinar_id) &&
              !Object.keys(timerResult).length
            "
            @click="openIframe()"
            class="event-detail__translation button button_white px-6"
          >
            Смотреть трансляцию
          </div>
        </div> -->
        <div class="event-detail__timer mt-10">
          <video
            controls
            src="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/video/2023/07/19/%D0%93%D0%B0%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0_%D1%84%D0%B8%D0%BD%D0%B0%D0%BB-min.mp4"
            poster="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/announce/2023/07/19/%D0%93%D0%B0%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0_%D1%84%D0%B8%D0%BD%D0%B0%D0%BB.jpeg"
          ></video>
        </div>
        <div class="event-detail__body">
          <div class="event-detail__main-info">
            <!-- <div class="event-detail__about-title">О мероприятии</div>
            <div class="event-detail__about-description">
              Приглашаем Вас на вебинар года "Программа клинических исследований
              "ГАЛАКТИКА" - ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ" 18 мая в 10:00 по Московскому
              времени! Снижение сердечно-сосудистых рисоков - вопрос
              Галактического масштаба, ответы на который дадут ведущие эксперты
              в кардиологии! <br /><br />
              <b>Узнайте больше о предстоящем событии!</b>
            </div> -->
            <InfoDrop
              @openInner="openInner"
              :item="{
                title: 'Тайм-код:',
                content: galaxyProgramm,
              }"
            />
            <div class="galaxy-activities mt-8">
              <div class="galaxy-activities__title">
                Предлагаем вам пройти несколько полезных активностей.
              </div>
              <div class="galaxy-activities__list">
                <!-- <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/1.svg')" />
                  </div>
                  <div
                    class="galaxy-activity__button button button_empty-pink"
                    @click="
                      toWidget(
                        { name: 'GalaxyFrame', params: { frame: 'maths' } },
                        'GALAXY Арифметика'
                      )
                    "
                  >
                    GALAXY Арифметика
                  </div>
                </div> -->
                <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/6.svg')" />
                  </div>
                  <div
                    class="galaxy-activity__button button button_empty-pink"
                    @click="
                      toWidget(
                        {
                          name: 'GalaxyFrame',
                          params: { frame: 'digital_quiz' },
                        },
                        'GALAXY Квиз'
                      )
                    "
                  >
                    GALAXY Квиз
                  </div>
                </div>
                <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/3.svg')" />
                  </div>
                  <div
                    class="galaxy-activity__button button button_empty-pink"
                    @click="
                      toWidget(
                        {
                          name: 'GalaxyFrame',
                          params: { frame: 'find_word' },
                        },
                        'Найдите слова'
                      )
                    "
                  >
                    Найдите слова
                  </div>
                </div>
                <!-- <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/4.svg')" />
                  </div>
                  <div
                    class="galaxy-activity__button button button_empty-pink"
                    @click="
                      toWidget(
                        {
                          name: 'GalaxyFrame',
                          params: { frame: 'galaxy_sync' },
                        },
                        'Галактическая синхронизация'
                      )
                    "
                  >
                    Галактическая синхронизация
                  </div>
                </div> -->
                <!-- <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/5.svg')" />
                  </div>
                  <div
                    class="galaxy-activity__button button button_empty-pink"
                    @click="
                      toWidget(
                        {
                          name: 'GalaxyFrame',
                          params: { frame: 'puzzle' },
                        },
                        'Соберите пазл'
                      )
                    "
                  >
                    Соберите пазл
                  </div>
                </div> -->
                <!-- <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/2.svg')" />
                  </div>
                  <div
                    class="galaxy-activity__button button button_empty-pink"
                    @click="
                      toWidget(
                        {
                          name: 'GalaxyFrame',
                          params: { frame: 'share' },
                        },
                        ' Делитесь ссылками'
                      )
                    "
                  >
                    Делитесь ссылками
                  </div>
                </div> -->
                <div class="galaxy-activities__item">
                  <div class="galaxy-activity__icon">
                    <img :src="require('@/assets/img/galaxy/icons/7.svg')" />
                  </div>
                  <div
                    @click="
                      toWidget(
                        {
                          name: 'GalaxyFrame',
                          params: { frame: 'callback' },
                        },
                        '  Обратная связь'
                      )
                    "
                    class="galaxy-activity__button button button_empty-pink"
                  >
                    Обратная связь
                  </div>
                </div>
              </div>
            </div>
            <div
              class="event-detail__iframe mb-8"
              v-if="
               ((IframeAccess &&
                Object.keys(IframeAccess).length &&
                IframeAccess[`event_${EventDetail.id}`] &&
                Object.keys(IframeAccess[`event_${EventDetail.id}`]).length) ||
                EventDetail.webinar_id) &&
              !Object.keys(timerResult).length &&
                openFrame
              "
              :class="{ 'event-detail__iframe_opened': openFrame }"
            >
              <div
                @click="openFrame = false"
                v-if="openFrame"
                class="event-detail__close-btn"
              >
                <span class="button button_pink button_small">Назад</span>
                <!-- <svg
                  class="d-xl-none"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.25 5.25L18.75 18.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                  <path
                    d="M18.75 5.25L5.25 18.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                </svg> -->
              </div>
              <iframe
                v-if="IframeAccess && IframeAccess[`event_${EventDetail.id}`] && Object.keys(IframeAccess[`event_${EventDetail.id}`]).length"
                class="d-xl-block"
                id="userplayer"
                frameborder="0"
                :src="IframeAccess[`event_${EventDetail.id}`].url + '&lang=Ru-ru&disableSupport=1'"
                allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
                allowusermedia
              ></iframe>
              <iframe
                v-else
                class="d-xl-block"
                id="userplayer"
                frameborder="0"
                :src="`https://edu.livedigital.space/room/${
                  EventDetail.webinar_id
                }/?participantName=${
                  $route.query.email || ''
                }&lang=Ru-ru&disableSupport=1`"
                allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
                allowusermedia
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <PopupEnd
        :eventName="'Galaxy'"
        :backUrl="{ name: 'Galaxy' }"
        @close="closePopup"
        v-if="popup && $route.query.access"
      />
      <PopUpDefault @close="popupActivity = false" v-if="popupActivity">
        <div class="popup__text">
          Данный интерактив будет доступен 18&#8209;го&nbsp;мая
        </div>
      </PopUpDefault>
    </div>
    <div v-else-if="EventDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="EventDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "EventDetail",
  components: { Breadcrumbs, InfoDrop, Choch, Error, PopupEnd, PopUpDefault },
  data: () => ({
    popup: false,
    popupActivity: false,
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
    intervalEnd: null,
    percent: null,
  }),
  computed: {
    ...mapGetters(["user", "IframeAccess"]),
    EventDetail() {
      return {
        id: 220, // 64 - dev 220 -prod
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        nosology: [
          {
            id: 3,
            title: "Кардиология",
            slug: "cardiology",
            icon: "/media/uploads/nosology/icons/2022/07/06/cardiology-icon.svg",
            active: true,
          },
        ],
        name: "«Программа клинических исследований «ГАЛАКТИКА» &mdash; ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ»",
        description: "«ГАЛАКТИКА» – ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ",
        banner_description:
          "10:00 – 10:10 – «Липидная теория атеросклероза» 110 лет за 10 минут – Сусеков А.В.<br><br>10:10 – 10:30 - Программа «Галактика» в 2023 г: результаты тысяч пациентов и для уверенного решения Обрезан А.Г. (Санкт-Петербург)<br><br>10:30 – 10:50 - Безопасность терапии статинами - сквозь туманность сомнений и противоречий. Бубнова М.Г.<br><br>10:50 – 11:10 - Рекомендации 2023 по липидам: большой взрыв или вспышки на солнце? – Кобалава Ж.Д.<br><br>11:10 – 11:30 - Созвездие нестатиновой терапии: белые карлики, погасшие звезды и сверхновые. Навигация для врачей. Каштолап В.В. (Кемерово)<br><br>11:30 – 11:50 - Отклонение от статиновой орбиты и как вернуться на верный курс? – Скибицкий В.В. (Краснодар)<br><br>11:50 – 12:10 - Космический шум и информационное поле – почему важен диалог с пациентом? Воробьёв А.С. (Сургут)<br><br>12:10 – 12:30 - Дискуссия экспертов",
        date: "2023-05-18T10:00:30+03:00",
        date_end: "2029-05-18T11:00:31+03:00",
        place: "",
        webinar_id: "mE2vnFvO9J",
        room_id: "6461ea8f4d7e4d8d0a895fc2",
      };
    },
    program() {
      return [
        {
          time: "0:06:00 - 0:18:00",
          title: "«Липидная теория атеросклероза» 110 лет за 10 минут",
          description:
            "Сусеков А.В. д.м.н., профессор кафедры клинической фармакологии РМАПО, крупнейший российский специалист в области липидологии и статинотерапии (Москва)",
        },
        {
          time: "0:19:00 - 0:36:00",
          title:
            "Программа «Галактика» в 2023 г: результаты тысяч пациентов и для уверенного решения",
          description:
            "Обрезан А.Г. д.м.н., профессор, главный врач Международного медицинского центра СОГАЗ, заведующий кафедрой госпитальной терапии Медицинского факультета СПбГУ. (Санкт-Петербург)",
        },
        {
          time: "0:37:00  - 1:07:00",
          title:
            "Безопасность терапии статинами - сквозь туманность сомнений и противоречий",
          description:
            "Бубнова М.Г. д.м.н., профессор, руководитель отдела реабилитации и вторичной профилактики сердечно-сосудистых заболеваний, вице-президент  Общероссийской общественной организации «Российское общество кардиосоматической реабилитации и вторичной профилактики» (Москва)",
        },
        {
          time: "1:08:00  - 1:52:00",
          title:
            "Рекомендации 2023 по липидам: большой взрыв или вспышки на солнце? ",
          description:
            "Кобалава Ж.Д. д.м.н., член-корреспондент РАН, профессор кафедры внутренних болезней с курсом кардиологии и функциональной диагностики имени В.С. Моисеева Медицинского института РУДН (Москва)",
        },
        {
          time: "1:53:00  - 2:15:00",
          title:
            "Созвездие нестатиновой терапии: белые карлики, погасшие звезды и сверхновые. Навигация для врачей.",
          description:
            "Каштолап В.В. д.м.н., доцент, начальник отдела организации инновационных и клинических исследований, заведующий лабораторией патофизиологии мультифокального атеросклероза ФГБНУ «Научно-исследовательский институт комплексных проблем сердечно-сосудистых заболеваний» (Кемерово)",
        },
        {
          time: "2:20:00  - 2:51:00",
          title:
            "Отклонение от статиновой орбиты и как вернуться на верный курс?",
          description:
            "Скибицкий В.В. (Краснодар) д.м.н., профессор, заведующий кафедрой Кубанского государственного медицинского университета Минздрава России, Заслуженный деятель науки Кубани, Заслуженный работник здравоохранения Кубани (Краснодар)",
        },
        {
          time: "2:52:00  - 3:09:00",
          title:
            "Космический шум и информационное поле – почему важен диалог с пациентом?",
          description:
            "Воробьёв А.С. к.м.н., доцент кафедры кардиологии, ведущий научный сотрудник НОЦ Медицинского института БУ ВО ХМАО-Югры «Сургутский государственный университет» (Сургут)",
        },
        {
          time: "3:09:00  - 3:12:00",
          title: "Дискуссия экспертов",
          description: "",
        },
      ];
    },
    galaxyProgramm() {
      let html = ``;
      let block = ``;
      for (let item of this.program) {
        block += `
              <div class="galaxy__program-item">
                <div class="galaxy__program-time">${item.time}</div>
                <div class="galaxy__program-info">
                  <div class="galaxy__program-title">
                   ${item.title}
                  </div>
                  <div class="galaxy__program-description">
                    ${item.description}
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="galaxy__program mb-8">${block}</div>`;
      return html;
    },
  },
  methods: {
    ...mapActions(["fetchEventsCalendar", "fetchIframeAccess"]),
    openInner() {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "see broadcast programm click", {
          "see broadcast programm click": {
            ...this.$root.ymFields,
          },
        });
      }
    },
    toWidget(to, name, closed) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "galaxy widget click", {
          "galaxy widget click": {
            "galaxy widget name": name,
            ...this.$root.ymFields,
          },
        });
      }
      if (!closed) {
        this.$router.push(to).catch(() => {});
      } else {
        this.popupActivity = true;
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    timerEnd() {
      let date = new Date();
      const dateEnd = this.$m.utc(this.EventDetail.date_end).utcOffset(+3);
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResultEnd", {});
      if (fullMonths) {
        this.$set(this.timerResultEnd, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResultEnd, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResultEnd, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResultEnd, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResultEnd, "seconds", fullSeconds);
      }
      if (
        !Object.keys(this.timerResultEnd).length &&
        !localStorage.getItem("token") &&
        !!!Object.keys(this.user).length &&
        !!this.$route.query.access
      ) {
        // this.popup = true;
      }
    },
    async getCalendar(id) {
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    closePopup() {
      this.popup = false;
    },
    webinarEnd(e) {
      if (e.origin !== document.location.origin) {
        if (e.data && e.data === "ld_finish_call") {
          if (
            !localStorage.getItem("token") &&
            !!!Object.keys(this.user).length &&
            !!this.$route.query.access
          ) {
            this.popup = true;
          }
        }
      }
    },
    openIframe() {
      if (
        !localStorage.getItem("token") &&
        !!!Object.keys(this.user).length &&
        !!this.$route.query.access
      ) {
        // this.popup = true;
        this.openFrame = true;
      } else {
        this.openFrame = true;
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "watch broadcast click", {
          "watch broadcast click": {
            ...this.$root.ymFields,
          },
        });
      }
    },
    cyclicVideo(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video 30 sec", {
            "video 30 sec": {
              "video name":
                "«Программа клинических исследований «ГАЛАКТИКА» — ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ»",
              "video duration": duration,
              ...this.$root.ymFields,
            },
          });
        }
        vm.cyclicVideo();
      }, 30000);
    },
  },
  async mounted() {
    const vm = this;
    // document.querySelector(
    //   "#app"
    // ).style.backgroundImage = `url(${require("@/assets/img/galaxy/back.jpg")})`;
    if (
      !localStorage.getItem("token") &&
      !!!Object.keys(this.user).length &&
      !!this.$route.query.access &&
      !this.popup
    ) {
      setTimeout(() => {
        this.popup = true;
      }, 10000);
    }
    window.addEventListener("message", this.webinarEnd, false);
    this.interval = setInterval(this.timer, 1000);
    this.intervalEnd = setInterval(this.timerEnd, 1000);
    const today = this.$m.utc(new Date()).utcOffset(+3);
    const dateEnd = this.$m
      .utc(this.EventDetail.date_end)
      .utcOffset(+3)
      .add(1, "hours");
    const diff = today.diff(dateEnd, "seconds");
    if (diff > 0) {
      this.$router.push({ name: "Events" });
    }
    let video = document.querySelector(".event-detail__timer video");
    video.addEventListener("play", function () {
      if (this.currentTime === 0) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video start", {
            "video start": {
              "video name":
                "«Программа клинических исследований «ГАЛАКТИКА» — ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ»",
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video resume", {
            "video resume": {
              "video name":
                "«Программа клинических исследований «ГАЛАКТИКА» — ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ»",
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      }
      vm.cyclicVideo(Math.round(this.duration));
    });
    video.addEventListener("pause", function () {
      if (this.currentTime !== this.duration) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video pause", {
            "video pause": {
              "video name":
                "«Программа клинических исследований «ГАЛАКТИКА» — ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ»",
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      }
      clearTimeout(vm.timer);
      vm.timer = null;
    });
    video.addEventListener("ended", function () {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "video end", {
          "video end": {
            "video name":
              "«Программа клинических исследований «ГАЛАКТИКА» — ОБЪЕДИНЯЕТ ПОКОЛЕНИЯ»",
            "video duration": Math.round(this.duration),
            ...vm.$root.ymFields,
          },
        });
      }
    });
    video.addEventListener("timeupdate", function () {
      let percent = Math.ceil((this.currentTime / this.duration) * 100);
      if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video percentage watched", {
            "video percentage watched": {
              "video percentage watched": percent,
              ...vm.$root.ymFields,
            },
          });
        }
        this.percent = percent;
      }
    });
  },
  beforeDestroy() {
    // document.querySelector("#app").style.backgroundImage = "";
    clearInterval(this.interval);
    clearInterval(this.intervalEnd);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {
        // if (this.$route.params.id && !this.$route.query.access) {
        //   await this.fetchEventDetail({id: this.$route.params.id});
        // }
      },
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        this.fetchIframeAccess(this.EventDetail.id);
        clearInterval(this.interval);
        clearInterval(this.intervalEnd);
      }
    },
    async EventDetail() {
      if (this.EventDetail.external) {
        window.open(this.EventDetail.external_link, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background-color: #d0006f;
    // background-image: url('~@/assets/img/galaxy/timer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    // &::before,
    // &::after {
    //   width: 100%;
    //   height: 22px;
    //   position: absolute;
    //   left: 0;
    //   background-repeat: no-repeat;
    //   background-size: 100%;
    //   background-color: #f3fdff;
    //   display: block;
    //   content: "";
    // }

    // &::before {
    //   top: 0;
    //   background-image: url('~@/assets/img/galaxy/timer_t.png');
    //   background-position: bottom left;
    // }

    //  &::after {
    //   bottom: 0;
    //   background-image: url('~@/assets/img/galaxy/timer_b.png');
    //   background-position: top left;
    // }

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1220px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #830051;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
    background-color: #f8f8f8;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.galaxy {
  &__program {
    &-item {
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      border: 1px solid #830051;
      padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #830051;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 65%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 64px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #656969;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
    }
  }
}
.event-detail {
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>
