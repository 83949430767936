var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.EventDetail && Object.keys(_vm.EventDetail).length)?_c('div',{staticClass:"event-detail"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"event-detail__title",domProps:{"innerHTML":_vm._s(_vm.EventDetail.name)}})]),_c('Breadcrumbs',{attrs:{"pages":[
        {
          name: _vm.EventDetail.name,
        } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"event-detail__about-title mt-6"},[_vm._v("Предлагаем Вам посмотреть наиболее полезную часть или прослушать своего любимого эксперта из вебинара года: «ГАЛАКТИКА» - ОБЪЕДИНЯЯ ПОКОЛЕНИЯ».")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"event-detail__body"},[_c('div',{staticClass:"event-detail__main-info"},[_c('InfoDrop',{attrs:{"item":{
              title: 'Тайм-код:',
              content: _vm.galaxyProgramm,
            }},on:{"openInner":_vm.openInner}}),_c('div',{staticClass:"galaxy-activities mt-8"},[_c('div',{staticClass:"galaxy-activities__title"},[_vm._v(" Предлагаем вам пройти несколько полезных активностей. ")]),_c('div',{staticClass:"galaxy-activities__list"},[_c('div',{staticClass:"galaxy-activities__item"},[_c('div',{staticClass:"galaxy-activity__icon"},[_c('img',{attrs:{"src":require('@/assets/img/galaxy/icons/6.svg')}})]),_c('div',{staticClass:"galaxy-activity__button button button_empty-pink",on:{"click":function($event){return _vm.toWidget(
                      {
                        name: 'GalaxyFrame',
                        params: { frame: 'digital_quiz' },
                      },
                      'GALAXY Квиз'
                    )}}},[_vm._v(" GALAXY Квиз ")])]),_c('div',{staticClass:"galaxy-activities__item"},[_c('div',{staticClass:"galaxy-activity__icon"},[_c('img',{attrs:{"src":require('@/assets/img/galaxy/icons/3.svg')}})]),_c('div',{staticClass:"galaxy-activity__button button button_empty-pink",on:{"click":function($event){return _vm.toWidget(
                      {
                        name: 'GalaxyFrame',
                        params: { frame: 'find_word' },
                      },
                      'Найдите слова'
                    )}}},[_vm._v(" Найдите слова ")])]),_c('div',{staticClass:"galaxy-activities__item"},[_c('div',{staticClass:"galaxy-activity__icon"},[_c('img',{attrs:{"src":require('@/assets/img/galaxy/icons/7.svg')}})]),_c('div',{staticClass:"galaxy-activity__button button button_empty-pink",on:{"click":function($event){return _vm.toWidget(
                      {
                        name: 'GalaxyFrame',
                        params: { frame: 'callback' },
                      },
                      '  Обратная связь'
                    )}}},[_vm._v(" Обратная связь ")])])])]),(
             ((_vm.IframeAccess &&
              Object.keys(_vm.IframeAccess).length &&
              _vm.IframeAccess[("event_" + (_vm.EventDetail.id))] &&
              Object.keys(_vm.IframeAccess[("event_" + (_vm.EventDetail.id))]).length) ||
              _vm.EventDetail.webinar_id) &&
            !Object.keys(_vm.timerResult).length &&
              _vm.openFrame
            )?_c('div',{staticClass:"event-detail__iframe mb-8",class:{ 'event-detail__iframe_opened': _vm.openFrame }},[(_vm.openFrame)?_c('div',{staticClass:"event-detail__close-btn",on:{"click":function($event){_vm.openFrame = false}}},[_c('span',{staticClass:"button button_pink button_small"},[_vm._v("Назад")])]):_vm._e(),(_vm.IframeAccess && _vm.IframeAccess[("event_" + (_vm.EventDetail.id))] && Object.keys(_vm.IframeAccess[("event_" + (_vm.EventDetail.id))]).length)?_c('iframe',{staticClass:"d-xl-block",attrs:{"id":"userplayer","frameborder":"0","src":_vm.IframeAccess[("event_" + (_vm.EventDetail.id))].url + '&lang=Ru-ru&disableSupport=1',"allow":"camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;","allowusermedia":""}}):_c('iframe',{staticClass:"d-xl-block",attrs:{"id":"userplayer","frameborder":"0","src":("https://edu.livedigital.space/room/" + (_vm.EventDetail.webinar_id) + "/?participantName=" + (_vm.$route.query.email || '') + "&lang=Ru-ru&disableSupport=1"),"allow":"camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;","allowusermedia":""}})]):_vm._e()],1)])]),(_vm.popup && _vm.$route.query.access)?_c('PopupEnd',{attrs:{"eventName":'Galaxy',"backUrl":{ name: 'Galaxy' }},on:{"close":_vm.closePopup}}):_vm._e(),(_vm.popupActivity)?_c('PopUpDefault',{on:{"close":function($event){_vm.popupActivity = false}}},[_c('div',{staticClass:"popup__text"},[_vm._v(" Данный интерактив будет доступен 18‑го мая ")])]):_vm._e()],1):(_vm.EventDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.EventDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-detail__about-description"},[_vm._v(" Из видео Вы узнаете: "),_c('br'),_c('br'),_c('ul',{staticStyle:{"color":"#656969 !important"}},[_c('li',[_vm._v("Липидную теорию атеросклероза;")]),_c('li',[_vm._v("Данные по безопасности применения статинов в 2023 году;")]),_c('li',[_vm._v("Обновленные рекомендации: \"нарушения липидного обмена\";")]),_c('li',[_vm._v("Ряд любопытных случаев о тактике применения статинов;")])]),_vm._v(" и многое другое... ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-detail__timer mt-10"},[_c('video',{attrs:{"controls":"","src":"https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/video/2023/07/19/%D0%93%D0%B0%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0_%D1%84%D0%B8%D0%BD%D0%B0%D0%BB-min.mp4","poster":"https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/announce/2023/07/19/%D0%93%D0%B0%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0_%D1%84%D0%B8%D0%BD%D0%B0%D0%BB.jpeg"}})])}]

export { render, staticRenderFns }